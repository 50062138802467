<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="primary--text">{{ title }}</span>
      </v-card-title>
      <v-form
        v-model="isFormValid"
        ref="select-afiliado"
        id="select-afiliado"
        @submit.prevent="selectAfiliado()"
      >
      <v-row v-show="busquedaAfiliado"> </v-row>
      <v-row class="p-3 pb-0" @keypress.enter="filterAfiliados">
        <v-col cols="6" sm="12" md="6" class="py-0">
          <v-text-field
            v-model="afiliadoSearchDocumento"
            label="N° de documento"
            dense
            clearable
            :rules="[
              rules.requiredTrim(afiliadoSearchDocumento),
              rules.maxLength(afiliadoSearchDocumento, 8)
            ]"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="12" md="6" class="py-0">
          <v-text-field
            v-model="afiliadoSearchNumero"
            label="N° de afiliado"
            dense
            clearable
            :rules="[
              rules.requiredTrim(afiliadoSearchNumero),
              rules.maxLength(afiliadoSearchNumero, 15)
            ]"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="12" md="6" class="py-0">
          <v-text-field
            v-model="afiliadoSearchApellido"
            label="Apellido/s"
            dense
            :rules="[
              rules.requiredTrim(afiliadoSearchApellido),
              rules.maxLength(afiliadoSearchApellido, 50)
            ]"
            clearable
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="12" md="6" class="py-0">
          <v-text-field
            v-model="afiliadoSearchNombre"
            label="Nombre/s"
            dense
            :rules="[
              rules.requiredTrim(afiliadoSearchNombre),
              rules.maxLength(afiliadoSearchNombre, 50)
            ]"
            clearable
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2" class="py-1 text-left">
          <v-btn color="primary" :disabled="!isFormValid" @click="filterAfiliados">
            Buscar
          </v-btn>
        </v-col>
      </v-row>

      
        <v-row>
          <v-col>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              v-if="filterApplied"
              :items="afiliadosResult"
              :loading="loading"
              dense
              show-select
              single-select
              v-model="afiliadoSelected"
              @input="selectedAfiliado()"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal()">
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            ref="select-afiliado"
            :disabled="afiliadoSelected.length == 0"
          >
            Seleccionar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "BusquedaAfiliado",
  props: {},
  directives: { mask },
  data() {
    return {
      title: "Búsqueda de afiliado",
      rules: rules,
      isFormValid: false,
      filtersApplyed: [],
      afiliadoSelected: [],
      grupoFamiliarARelacionar: null,
      afiliadosResult: [],
      dataTableHeight: null,
      benId: null,
      afiliadoSearchNombre: null,
      afiliadoSearchDocumento: null,
      afiliadoSearchApellido: null,
      afiliadoSearchNumero: null,
      filterApplied: false,
      banderaAfiliadoSelected: false,
      busquedaAfiliado: true,
      loading: false,
      headers: [
        {
          text: "Afiliado",
          align: "start",
          sortable: true,
          value: "value"
        },
        {
          text: "N° de documento",
          sortable: false,
          value: "doc"
        },
        {
          text: "N° de afiliado",
          sortable: false,
          value: "numero"
        }
      ]
    };
  },
  watch: {
    afiliadosResult(val) {
      if (val.length >= 4) {
        this.dataTableHeight = "10rem";
      } else if (val.length >= 2) {
        this.dataTableHeight = "7rem";
      } else {
        this.dataTableHeight = "5rem";
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAfiliadosByParams: "agenteCuenta/fetchAfiliadosByParams",
      setAlert: "user/setAlert"
    }),
    selectAfiliado() {
      this.$emit("toggleModalBusquedaAfi");
      this.$emit("findAfiliado", this.afiliadoSelected[0]);
    },
    closeModal() {
      this.$emit("toggleModalBusquedaAfi");
    },
    // metodos grupos familiares filter
    async filterAfiliados() {
      if (
        this.afiliadoSearchNombre != null ||
        this.afiliadoSearchNumero != null ||
        this.afiliadoSearchDocumento != null ||
        this.afiliadoSearchApellido != null
      ) {
        this.loading = true;
        this.customizeFiltersApplied();
        const data = {
          apellido: this.afiliadoSearchApellido,
          dni: parseInt(this.afiliadoSearchDocumento),
          numero: this.afiliadoSearchNumero,
          nombre: this.afiliadoSearchNombre
        };
        try {
          this.filterApplied = true;
          const response = await this.fetchAfiliadosByParams(data);
          this.afiliadosResult = response;
          this.banderaAfiliadoSelected
            ? (this.afiliadoSelected = this.afiliadosResult)
            : [];
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda"
        });
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];

      //  chequea si ya selecciono un afiliado en el data table
      if (this.afiliadoSelected.length > 0) {
        this.filtersApplyed.splice(0, 1, {
          key: "value",
          label: "Afiliado",
          model: this.afiliadoSelected[0].value.toUpperCase()
        });
        // aca chequea si todavia no selecciono afiliado pero escondio el modal de los filtros
      } else {
        if (this.afiliadoSearchDocumento) {
          this.filtersApplyed.splice(0, 1, {
            key: "dni",
            label: "N° de documento",
            model: this.afiliadoSearchDocumento
          });
        }
        if (this.afiliadoSearchNumero) {
          this.filtersApplyed.splice(1, 1, {
            key: "numero",
            label: "N° de afiliado",
            model: this.afiliadoSearchNumero
          });
        }

        if (this.afiliadoSearchNombre) {
          this.filtersApplyed.splice(2, 1, {
            key: "nombre",
            label: "Nombre",
            model: this.afiliadoSearchNombre.toUpperCase()
          });
        }
        if (this.afiliadoSearchApellido) {
          this.filtersApplyed.splice(3, 1, {
            key: "apellido",
            label: "Apellido",
            model: this.afiliadoSearchApellido.toUpperCase()
          });
        }
      }
    },
    selectedAfiliado() {
      if (this.afiliadoSelected.length > 0) {
        this.benId = this.afiliadoSelected[0].id;
        this.banderaAfiliadoSelected = false;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
